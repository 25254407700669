// eslint-disable-next-line import/order
import { captureRemixErrorBoundaryError, withSentry } from '@sentry/remix'

import { useEffect } from 'react'

declare global {
  interface Window {
    dataLayer: unknown[]
  }
}

import { LinksFunction, MetaFunction } from '@remix-run/node'
import {
  Links,
  Meta,
  Outlet,
  Scripts,
  ScrollRestoration,
  useRouteError,
} from '@remix-run/react'

import {
  CustomErrorPage,
  RootErrorBoundary,
  TopFooter,
  TopHeader,
} from './features'
import './tailwind.css'

// FIXME: ここはビジネス側と相談して後で正しいものに置き換える
export const meta: MetaFunction = () => {
  return [
    {
      title: 'オフィスマップ | シェアオフィス コワーキングスペース検索サイト',
    },
    {
      name: 'description',
      content:
        'シェアオフィス コワーキングスペース検索サイト。シェアオフィスの詳細情報、地図、アクセス方法を確認できます。',
    },
  ]
}
export const links: LinksFunction = () => {
  return [
    {
      rel: 'stylesheet',
      href: 'https://fonts.googleapis.com/css2?family=Noto+Sans+JP:wght@400;700&display=swap',
    },
  ]
}

export function ErrorBoundary() {
  const error = useRouteError()
  captureRemixErrorBoundaryError(error)
  return (
    <RootErrorBoundary>
      <div className="flex min-h-screen flex-col">
        <TopHeader />
        <div className="flex flex-grow items-center justify-center">
          <CustomErrorPage error={error} />
        </div>
        <TopFooter />
      </div>
    </RootErrorBoundary>
  )
}

export function Layout({ children }: { children: React.ReactNode }) {
  useEffect(() => {
    // initialize Clarity
    import('@microsoft/clarity').then((clarity) => {
      clarity.default.init('q0r7ru9fdp')
    })

    // Google Tag Manager
    const gtmScript = document.createElement('script')
    gtmScript.async = true
    gtmScript.src = `https://www.googletagmanager.com/gtm.js?id=GTM-KKFTVX32`
    document.head.appendChild(gtmScript)

    // Google Tag Manager (noscript)
    const noscript = document.createElement('noscript')
    noscript.innerHTML = `<iframe src="https://www.googletagmanager.com/ns.html?id=GTM-KKFTVX32"
        height="0" width="0" style="display:none;visibility:hidden"></iframe>`
    document.body.insertBefore(noscript, document.body.firstChild)

    // Google tag (gtag.js)
    const script = document.createElement('script')
    script.src = 'https://www.googletagmanager.com/gtag/js?id=G-MFJ57HQCM2'
    script.async = true
    document.head.appendChild(script)

    script.onload = () => {
      window.dataLayer = window.dataLayer || []
      function gtag(...args: unknown[]) {
        window.dataLayer.push(args)
      }
      gtag('js', new Date())
      gtag('config', 'G-MFJ57HQCM2')
    }
  })

  return (
    <html lang="en">
      <head>
        <meta charSet="utf-8" />
        <meta content="width=device-width, initial-scale=1" name="viewport" />
        <Meta />
        <Links />
      </head>

      <body>
        {children}
        <ScrollRestoration />
        <Scripts />
      </body>
    </html>
  )
}

function App() {
  return <Outlet />
}

export default withSentry(App)
