import { useEffect } from 'react'

// eslint-disable-next-line @typescript-eslint/naming-convention
import * as Sentry from '@sentry/react'
import { useRouteError, isRouteErrorResponse } from 'react-router-dom'

/**
 * シンプルなルートエラーバウンダリーコンポーネント
 * React Routerのエラーをキャプチャし、Sentryに送信
 */
export function RootErrorBoundary({ children }: { children: React.ReactNode }) {
  const error = useRouteError()

  useEffect(() => {
    let errorType = 'unknown'
    let statusCode
    let errorMessage = 'エラーが発生しました'

    if (isRouteErrorResponse(error)) {
      errorType = 'route_error_response'
      statusCode = error.status
      errorMessage =
        error.statusText || error.data?.message || 'Route error occurred'
    } else if (error instanceof Error) {
      errorType = 'error_object'
      errorMessage = error.message
    } else if (typeof error === 'string') {
      errorType = 'string_error'
      errorMessage = error
    }

    // エラーをSentryに送信
    reportErrorToSentry(error, {
      source: 'router_error_boundary',
      errorType: errorType,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      status_code: statusCode,
      location: window.location.href,
      pathname: window.location.pathname,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      route_data: isRouteErrorResponse(error) ? error.data : undefined,
      // eslint-disable-next-line @typescript-eslint/naming-convention
      error_message: errorMessage,
      timestamp: new Date().toISOString(),
    })
  }, [error])

  return children
}

export const reportErrorToSentry = (
  error: unknown,
  context?: Record<string, unknown>,
  level: Sentry.SeverityLevel = 'error',
) => {
  Sentry.withScope((scope) => {
    scope.setLevel(level)

    // コンテキスト情報を追加
    if (context) {
      Object.entries(context).forEach(([key, value]) => {
        scope.setExtra(key, value)
      })
    }

    if (error instanceof Error) {
      Sentry.captureException(error)
    } else if (typeof error === 'string') {
      Sentry.captureMessage(error)
    } else {
      Sentry.captureMessage('Unknown error occurred')
    }
  })
}
