import { InternalServerErrorImage } from './internal-server-error-image'

export const InternalServerError = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <InternalServerErrorImage className="h-[74px] w-[146px] md:h-[136px] md:w-[260px]" />
      <p className="text-[22px] font-bold leading-[140%] text-brand md:text-[28px]">
        サーバーで問題が発生しました
      </p>
      <p className="text-body-s text-grayText md:text-body">
        しばらくしてから再度アクセスしてください。
      </p>
    </div>
  )
}
