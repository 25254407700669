type Props = {
  className?: string
}

export const UnauthorizedImage = ({ className }: Props) => {
  return (
    <svg
      className={className}
      fill="none"
      height="138"
      viewBox="0 0 260 138"
      width="260"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_4042_6264)">
        <path
          d="M127.314 120.83C124.208 107.426 118.976 95.9153 111.801 86.647C107.233 80.7335 103.612 75.4514 97.649 66.7145L97.5657 66.5983C92.4498 59.1072 89.8589 50.8189 90.075 42.6302C90.4734 27.6647 99.0942 17.3665 101.735 14.5594C104.741 11.3537 115.804 0.922607 131.833 0.922607C147.861 0.922607 158.99 11.3537 161.996 14.5594C164.637 17.3665 173.258 27.6647 173.656 42.6302C173.938 52.9781 169.736 61.3828 166.165 66.5983C163.906 69.9037 161.979 72.7108 160.219 75.252L160.268 75.2853C157.029 79.9527 154.621 83.2411 151.997 86.6635C144.821 95.9317 139.605 107.442 136.483 120.846H127.297L127.314 120.83ZM131.849 10.3405C119.607 10.3405 110.97 18.4959 108.628 21.0041C107.166 22.5654 99.8415 30.9367 99.5256 42.8626C99.3103 50.6859 102.582 57.197 105.372 61.2665C111.335 69.9867 114.873 75.1857 119.275 80.8663C124.408 87.5106 128.643 95.1176 131.915 103.622C135.187 95.1341 139.407 87.5436 144.539 80.8994L144.489 80.8663C148.891 75.169 152.445 69.9702 158.325 61.3662C161.182 57.197 164.454 50.6859 164.239 42.8626C163.923 30.9367 156.598 22.5654 155.136 21.0041C153.458 19.2267 144.472 10.3405 131.849 10.3405Z"
          fill="url(#paint0_linear_4042_6264)"
        />
        <path
          d="M131.82 64.5551C121.505 64.5551 113.117 56.167 113.117 45.8522C113.117 35.5375 121.505 27.1494 131.82 27.1494C142.135 27.1494 150.523 35.5375 150.523 45.8522C150.523 56.167 142.135 64.5551 131.82 64.5551ZM131.82 35.8032C126.272 35.8032 121.771 40.3212 121.771 45.8522C121.771 51.3834 126.289 55.9012 131.82 55.9012C137.351 55.9012 141.869 51.3834 141.869 45.8522C141.869 40.3212 137.351 35.8032 131.82 35.8032Z"
          fill="#10909E"
        />
        <path
          d="M62.8469 15.3967V51.8571H75.7154V63.0098H62.8469V76.3076H51.2652V63.0098H16.9492V52.715L52.981 15.3967H62.8469ZM51.2652 51.8571V31.9538L31.8767 51.8571H51.2652Z"
          fill="#10909E"
        />
        <path
          d="M215.458 15.3967V76.3076H203.876V26.5494H193.152V15.3967H215.458Z"
          fill="#10909E"
        />
      </g>
      <path
        d="M252.346 114.846H245.281L250.633 130.397H9.36692L14.7194 114.846H7.6537L0 137.077H260L252.346 114.846Z"
        fill="url(#paint1_linear_4042_6264)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_4042_6264"
          x1="173.67"
          x2="90.0625"
          y1="60.8844"
          y2="60.8844"
        >
          <stop stopColor="#F8B62D" />
          <stop offset="1" stopColor="#E71F19" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_4042_6264"
          x1="34.0919"
          x2="34.0919"
          y1="137.077"
          y2="114.846"
        >
          <stop stopColor="#178A97" />
          <stop offset="1" stopColor="#59C4F1" />
        </linearGradient>
        <clipPath id="clip0_4042_6264">
          <rect
            fill="white"
            height="119.924"
            transform="translate(16.9492 0.922607)"
            width="226.1"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
