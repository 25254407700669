type Props = {
  className?: string
}

export const InternalServerErrorImage = ({ className }: Props) => {
  return (
    <svg
      className={className}
      fill="none"
      height="132"
      viewBox="0 0 260 132"
      width="260"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3911_8594)">
        <mask
          height="117"
          id="mask0_3911_8594"
          maskUnits="userSpaceOnUse"
          width="242"
          x="9"
          y="-1"
        >
          <path
            d="M250.582 -0.00610352H9.37891V115.775H250.582V-0.00610352Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3911_8594)">
          <path
            d="M123.794 114.839C120.839 102.084 115.861 91.1312 109.033 82.312C104.687 76.6849 101.241 71.6588 95.5666 63.3451L95.4873 63.2345C90.6193 56.1063 88.1539 48.2195 88.3595 40.4276C88.7387 26.1871 96.9418 16.3877 99.4543 13.7167C102.315 10.6662 112.842 0.740479 128.094 0.740479C143.346 0.740479 153.936 10.6662 156.796 13.7167C159.309 16.3877 167.512 26.1871 167.892 40.4276C168.16 50.2742 164.162 58.2717 160.763 63.2345C158.614 66.3798 156.78 69.051 155.105 71.469L155.152 71.5007C152.07 75.942 149.779 79.0711 147.281 82.3277C140.453 91.1469 135.49 102.1 132.52 114.854H123.779L123.794 114.839ZM128.11 9.70209C116.461 9.70209 108.242 17.4624 106.014 19.8491C104.623 21.3348 97.6529 29.3006 97.3523 40.6488C97.1474 48.093 100.26 54.2887 102.916 58.1611C108.59 66.4588 111.956 71.4059 116.145 76.8113C121.029 83.1338 125.059 90.3722 128.173 98.4647C131.286 90.3879 135.301 83.1652 140.185 76.8429L140.137 76.8113C144.326 71.3901 147.708 66.4431 153.303 58.2559C156.021 54.2887 159.135 48.093 158.93 40.6488C158.63 29.3006 151.66 21.3348 150.269 19.8491C148.672 18.1579 140.121 9.70209 128.11 9.70209Z"
            fill="url(#paint0_linear_3911_8594)"
          />
          <path
            d="M128.085 61.2911C118.271 61.2911 110.289 53.3094 110.289 43.4942C110.289 33.6792 118.271 25.6975 128.085 25.6975C137.901 25.6975 145.882 33.6792 145.882 43.4942C145.882 53.3094 137.901 61.2911 128.085 61.2911ZM128.085 33.9321C122.807 33.9321 118.524 38.2311 118.524 43.4942C118.524 48.7575 122.823 53.0564 128.085 53.0564C133.349 53.0564 137.648 48.7575 137.648 43.4942C137.648 38.2311 133.349 33.9321 128.085 33.9321Z"
            fill="#10909E"
          />
          <path
            d="M206.556 13.2893C224.026 13.2893 236.761 26.8405 236.761 43.4935C236.761 60.1468 224.026 73.698 206.556 73.698C189.086 73.698 176.352 60.1468 176.352 43.4935C176.352 26.8405 189.086 13.2893 206.556 13.2893ZM206.556 62.7591C216.515 62.7591 224.516 54.6777 224.516 43.4935C224.516 32.31 216.515 24.2283 206.556 24.2283C196.597 24.2283 188.597 32.31 188.597 43.4935C188.597 54.6777 196.597 62.7591 206.556 62.7591Z"
            fill="#10909E"
          />
          <path
            d="M35.8913 13.9419H73.0351L69.6065 24.5543H43.3204L39.973 34.9214C43.1571 33.452 47.1568 32.0646 52.8712 32.0646C65.6881 32.0646 74.2593 38.9219 74.2593 51.4115C74.2593 63.0855 66.831 73.0449 49.4425 73.0449C43.8102 73.0449 34.3406 71.9833 24.8711 60.4733L30.912 53.0442C32.4627 54.4323 39.3199 62.4325 49.6058 62.4325C56.9529 62.4325 62.0142 58.3508 62.0142 51.4115C62.0142 43.6567 55.81 41.7787 49.116 41.7787C45.3608 41.7787 38.4223 42.3505 34.5852 45.2893L26.9116 42.1872L35.8913 13.9419Z"
            fill="#10909E"
          />
        </g>
      </g>
      <path
        d="M252.346 109.775H245.281L250.633 125.325H9.36692L14.7194 109.775H7.6537L0 132.006H260L252.346 109.775Z"
        fill="url(#paint1_linear_3911_8594)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3911_8594"
          x1="167.904"
          x2="88.3477"
          y1="57.7975"
          y2="57.7975"
        >
          <stop stopColor="#F8B62D" />
          <stop offset="1" stopColor="#E71F19" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3911_8594"
          x1="34.0919"
          x2="34.0919"
          y1="132.006"
          y2="109.775"
        >
          <stop stopColor="#178A97" />
          <stop offset="1" stopColor="#59C4F1" />
        </linearGradient>
        <clipPath id="clip0_3911_8594">
          <rect
            fill="white"
            height="115.781"
            transform="translate(9.39844 -0.00610352)"
            width="241.204"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
