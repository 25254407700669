type Props = {
  className?: string
}

export const NotFoundImage = ({ className }: Props) => {
  return (
    <svg
      className={className}
      fill="none"
      height="132"
      viewBox="0 0 260 132"
      width="260"
      xmlns="http://www.w3.org/2000/svg"
    >
      <g clipPath="url(#clip0_3911_7544)">
        <mask
          height="117"
          id="mask0_3911_7544"
          maskUnits="userSpaceOnUse"
          width="242"
          x="9"
          y="-1"
        >
          <path
            d="M250.582 -0.00610352H9.37891V115.775H250.582V-0.00610352Z"
            fill="white"
          />
        </mask>
        <g mask="url(#mask0_3911_7544)">
          <path
            d="M123.794 114.839C120.839 102.084 115.861 91.1312 109.033 82.312C104.687 76.6849 101.241 71.6588 95.5666 63.3451L95.4873 63.2345C90.6193 56.1063 88.1539 48.2195 88.3595 40.4276C88.7387 26.1871 96.9418 16.3877 99.4543 13.7167C102.315 10.6662 112.842 0.740479 128.094 0.740479C143.346 0.740479 153.936 10.6662 156.796 13.7167C159.309 16.3877 167.512 26.1871 167.892 40.4276C168.16 50.2742 164.162 58.2717 160.763 63.2345C158.614 66.3798 156.78 69.051 155.105 71.469L155.152 71.5007C152.07 75.942 149.779 79.0711 147.281 82.3277C140.453 91.1469 135.49 102.1 132.52 114.854H123.779L123.794 114.839ZM128.11 9.70209C116.461 9.70209 108.242 17.4624 106.014 19.8491C104.623 21.3348 97.6529 29.3006 97.3523 40.6488C97.1474 48.093 100.26 54.2887 102.916 58.1611C108.59 66.4588 111.956 71.4059 116.145 76.8113C121.029 83.1338 125.059 90.3722 128.173 98.4647C131.286 90.3879 135.301 83.1652 140.185 76.8429L140.137 76.8113C144.326 71.3901 147.708 66.4431 153.303 58.2559C156.021 54.2887 159.135 48.093 158.93 40.6488C158.63 29.3006 151.66 21.3348 150.269 19.8491C148.672 18.1579 140.121 9.70209 128.11 9.70209Z"
            fill="url(#paint0_linear_3911_7544)"
          />
          <path
            d="M128.085 61.2911C118.271 61.2911 110.289 53.3094 110.289 43.4942C110.289 33.6792 118.271 25.6975 128.085 25.6975C137.901 25.6975 145.882 33.6792 145.882 43.4942C145.882 53.3094 137.901 61.2911 128.085 61.2911ZM128.085 33.9321C122.807 33.9321 118.524 38.2311 118.524 43.4942C118.524 48.7575 122.823 53.0564 128.085 53.0564C133.349 53.0564 137.648 48.7575 137.648 43.4942C137.648 38.2311 133.349 33.9321 128.085 33.9321Z"
            fill="#10909E"
          />
          <path
            d="M65.2914 14.5137V49.2077H77.5365V59.8201H65.2914V72.4738H54.2708V59.8201H21.6172V50.0241L55.9035 14.5137H65.2914ZM54.2708 49.2077V30.2686L35.8215 49.2077H54.2708Z"
            fill="#10909E"
          />
          <path
            d="M222.268 14.5137V49.2077H234.513V59.8201H222.268V72.4738H211.247V59.8201H178.594V50.0241L212.88 14.5137H222.268ZM211.247 49.2077V30.2686L192.798 49.2077H211.247Z"
            fill="#10909E"
          />
        </g>
      </g>
      <path
        d="M252.346 109.775H245.281L250.633 125.325H9.36692L14.7194 109.775H7.6537L0 132.006H260L252.346 109.775Z"
        fill="url(#paint1_linear_3911_7544)"
      />
      <defs>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint0_linear_3911_7544"
          x1="167.904"
          x2="88.3477"
          y1="57.7975"
          y2="57.7975"
        >
          <stop stopColor="#F8B62D" />
          <stop offset="1" stopColor="#E71F19" />
        </linearGradient>
        <linearGradient
          gradientUnits="userSpaceOnUse"
          id="paint1_linear_3911_7544"
          x1="34.0919"
          x2="34.0919"
          y1="132.006"
          y2="109.775"
        >
          <stop stopColor="#178A97" />
          <stop offset="1" stopColor="#59C4F1" />
        </linearGradient>
        <clipPath id="clip0_3911_7544">
          <rect
            fill="white"
            height="115.781"
            transform="translate(9.39844 -0.00610352)"
            width="241.204"
          />
        </clipPath>
      </defs>
    </svg>
  )
}
