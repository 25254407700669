import { UnauthorizedImage } from './unauthorized-image'

export const Unauthorized = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <UnauthorizedImage className="h-[74px] w-[146px] md:h-[136px] md:w-[260px]" />
      <p className="text-[22px] font-bold leading-[140%] text-brand md:text-[28px]">
        認証に失敗しました
      </p>
      <p className="text-body-s text-grayText md:text-body">
        ログインが必要です。認証情報を確認してください。
      </p>
    </div>
  )
}
