import { Link } from '@remix-run/react'
import { match, P } from 'ts-pattern'

import { Container, PrimaryButton } from '~/components'

import {
  BadRequest,
  InternalServerError,
  NotFound,
  Unauthorized,
} from '../components'

type CustomErrorPageProps = {
  error: unknown
}

export const CustomErrorPage = ({ error }: CustomErrorPageProps) => {
  const statusCode = match(error)
    .with({ statusCode: P.number }, (e) => e.statusCode)
    .with({ status: P.number }, (e) => e.status)
    .otherwise(() => 500)

  const ErrorComponent = match(statusCode)
    .with(400, () => BadRequest)
    .with(401, () => Unauthorized)
    .with(404, () => NotFound)
    .with(500, () => InternalServerError)
    .otherwise(() => InternalServerError)

  return (
    <main className="flex flex-1 items-center justify-center">
      <Container className="mb-14 mt-10 flex flex-col items-center justify-center gap-y-10">
        <ErrorComponent />
        <Link to="/">
          <PrimaryButton
            className="h-[52px] w-[220px] md:w-[340px]"
            color="brand"
          >
            トップページへ
          </PrimaryButton>
        </Link>
      </Container>
    </main>
  )
}
