import { NotFoundImage } from './not-found-image'

export const NotFound = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <NotFoundImage className="h-[74px] w-[146px] md:h-[136px] md:w-[260px]" />
      <p className="text-[22px] font-bold leading-[140%] text-brand md:text-[28px]">
        ページが見つかりませんでした
      </p>
      <p className="text-body-s text-grayText md:text-body">
        お探しのページはURLが間違っているか、削除された可能性があります。
      </p>
    </div>
  )
}
