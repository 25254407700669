import { BadRequestImage } from './bad-request-image'

export const BadRequest = () => {
  return (
    <div className="flex flex-col items-center justify-center gap-2">
      <BadRequestImage className="h-[74px] w-[146px] md:h-[136px] md:w-[260px]" />
      <p className="text-[22px] font-bold leading-[140%] text-brand md:text-[28px]">
        リクエストが無効です
      </p>
      <p className="text-body-s text-grayText md:text-body">
        入力内容をご確認の上、もう一度お試しください。
      </p>
    </div>
  )
}
